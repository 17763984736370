<!-- 编辑弹窗 -->
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  line-height: 78px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
<template>
  <el-dialog
    :title="isUpdate?'修改模块':'添加模块'"
    :visible="visible"
    width="600px"
    :destroy-on-close="true"
    :lock-scroll="false"
    custom-class="ele-dialog-form"
    @update:visible="updateVisible">
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="82px"
      @keyup.enter.native="save"
      @submit.native.prevent>
      <el-row :gutter="15">
        <el-col :sm="22">
          <el-form-item
            label="模块名称:"
            prop="title">
            <el-input
              v-model="form.title"
              placeholder="请输入模块名称"
              clearable/>
          </el-form-item>
        </el-col>
      </el-row>
<!--      <el-row :gutter="15">
        <el-col :sm="22">
          <el-form-item
            label="副标题:"
            prop="subtitle">
            <el-input
              v-model="form.subtitle"
              placeholder="请输入副标题"
              clearable
              :rows="3"
              type="textarea"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :sm="22">
          <el-form-item
            label="训练目标:"
            prop="training">
            <el-input
              v-model="form.training"
              placeholder="请输入训练目标"
              clearable
              :rows="3"
              type="textarea"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :sm="22">
          <el-form-item
            label="课时安排:"
            prop="arrange">
            <el-input
              v-model="form.arrange"
              placeholder="请输入课时安排"
              clearable
              :rows="3"
              type="textarea"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :sm="22">
          <el-form-item
            label="答题时长:"
            prop="length">
            <el-input
              v-model="form.length"
              placeholder="请输入答题时长"
              clearable
            type="number"/>
          </el-form-item>
        </el-col>
      </el-row>-->
      <el-row :gutter="15">
        <el-col :sm="22">
          <el-form-item
            label="图标:"
            prop="image">
<!--            {{$baseImgUrl + form.image}}-->
            <el-upload
              class="avatar-uploader"
              :action="$http.defaults.baseURL+'upload/image/module_upload/image'"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="form.image" :src="$baseImgUrl + form.image"  v-model="form.image" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :sm="22">
          <el-form-item
            label="前测图标:"
            prop="before_image">
            <!--            {{$baseImgUrl + form.image}}-->
            <el-upload
              class="avatar-uploader"
              :action="$http.defaults.baseURL+'upload/image/module_upload/image'"
              :show-file-list="false"
              :on-success="handleAvatarSuccess2"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="form.before_image" :src="$baseImgUrl + form.before_image"  v-model="form.before_image" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :sm="22">
          <el-form-item
            label="code码:"
            prop="code">
            <el-input
              v-model="form.code"
              placeholder="请输入code码"
              clearable/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="15">
        <el-col :sm="22">
          <el-form-item label="排序号:" prop="sort">
            <el-input-number
              v-model="form.sort"
              controls-position="right"
              :min="0"
              placeholder="请输入排序号"
              class="ele-fluid ele-text-left"/>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer">
      <el-button
        @click="updateVisible(false)">取消
      </el-button>
      <el-button
        type="primary"
        @click="save"
        :loading="loading">保存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>


import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import user from '../../../store/modules/user'
// import * as $http from "@vue/cli-service/lib/options";

export default {
  name: 'MenuEdit',
  components: {Treeselect},
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    // 全部菜单数据
    menuList: Array,
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        title: [
          {required: true, message: '请输入模块名称', trigger: 'blur'}
        ],
        /*subtitle: [
          {required: true, message: '请输入副标题名称', trigger: 'blur'}
        ],
        training: [
          {required: true, message: '请输入训练目标', trigger: 'blur'}
        ],
        arrange: [
          {required: true, message: '请输入课程安排', trigger: 'blur'}
        ],*/
        image: [
          {required: true, message: '请上传图片', trigger: 'blur'}
        ],
        before_image: [
          {required: true, message: '请上传前测图标', trigger: 'blur'}
        ],
       /* length:[
          {required: true, message: '输入答题时长', trigger: 'blur'}
        ],*/
        code: [
          {required: true, message: '请输入code码', trigger: 'blur'}
        ],
        /*sort: [
          {required: true, message: '请输入序列号', trigger: 'blur'}
        ]*/
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 预览图地址
      // image: '',
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.$delete(this.data, 'children')
        this.form = Object.assign({}, this.data, {
          parent_id: this.data.parent_id === 0 ? null : this.data.parent_id,
          sort: this.data.sort ? this.data.sort : 0
        });
        this.isUpdate = !!this.data.id;
      } else {
        this.form = {
          sort: 0
        };
        this.isUpdate = false;
      }
    }
  },
  methods: {
    // 上传成功
    handleAvatarSuccess(res, file) {
      // this.$set(this.form, 'image', process.env.VUE_APP_BASE_URL + res.data.path)
      this.$set(this.form, 'image', res.data.path)
    },
    // 上传之前的回调
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      // const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error('上传图片只能是 JPG 格式!');
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传图片大小不能超过 2MB!');
      // }
      // return isJPG && isLt2M;
    },
    // 上传成功
    handleAvatarSuccess2(res, file) {
      // this.$set(this.form, 'image', process.env.VUE_APP_BASE_URL + res.data.path)
      this.$set(this.form, 'before_image', res.data.path)
    },
    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http[this.isUpdate ? 'put' : 'post'](this.isUpdate ? '/Admin/module/' + this.data.id : '/Admin/module',
            Object.assign({}, this.form, {
              parent_id: this.form.parent_id || 0
            })
          ).then(res => {
            this.loading = false;
            if (res.data.code === 200) {
              // this.$store.state.user.getMenus();
              this.$message({type: 'success', message: res.data.msg});
              this.updateVisible(false);
              this.$emit('done');
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            this.loading = false;
            this.$message.error(e.message);
          });
        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
      //this.header[setting.tokenHeaderName] = store.state.user.token
    }
  }
}
</script>
<style scoped>

</style>
